import React from 'react';

import { Box, Text, Title } from '../components/atoms';
import Inner from '../components/templates/Inner';

export default () => (
  <Inner title="Core Values">
    <Box maxWidth="700px">
      <Title as="h1" mb={64}>
        Core Values
      </Title>
      <Title size="h4" mb="xxl">
        Four things that we believe in:
      </Title>
      <Title as="h3" size="h5" mb="s">
        Cultivate a growth mindset
      </Title>
      <Text mb="l">
        We are all lifelong students and self-improvers. We are only interested in people with a
        growth mindset. And therefore, we have a relentless ambition to improve ourselves — both
        professionally and personally.
      </Text>
      <Title as="h3" size="h5" mb="s">
        Be creative but stay accountable
      </Title>
      <Text mb="l">
        We document our processes and set deadlines so as not to limit ourselves. But to be able to
        do repetitive things faster and have more time for experiments and innovation.
      </Text>
      <Title as="h3" size="h5" mb="s">
        Every single interaction matters
      </Title>
      <Text mb="l">
        This includes everything - every layer of the overall operations. The way we communicate,
        work and behave. Even the smallest of things may lead to fantastic results collectively. We
        call this synergy.
      </Text>
      <Title as="h3" size="h5" mb="s">
        Don&apos;t be a victim
      </Title>
      <Text>
        Victim mentality is about avoiding an opportunity to handle a challenge. Some tend to easily
        fall into the act of not trying or simply giving up, usually blaming it on circumstances. It
        can only bring bad feelings such as anger, hopelessness, and depression. For us, problems
        are as natural as life itself, and we rather enjoy cracking them. They teach us some worthy
        life lessons, bringing new experience and knowledge ultimately making us better.
      </Text>
      <Title size="h4" mt={56} mb="xxl">
        Why we exist:
      </Title>
      <Title as="h3" size="h5" mb="s">
        Purpose
      </Title>
      <Text>
        We&apos;re here to help SaaS founders and Digital Product Owners to create effective,
        aesthetically appealing, and meaningful products! Those that will become a game-changer,
        delivering true value to your customers, making them feel happy and delightful.
      </Text>
      <Title as="h3" size="h5" mb="s">
        Mission
      </Title>
      <Text>
        Our intention is to provide tailored assistance to every digital product owner, with a deep
        understanding of the product, business delicacies, and modern trends of digital product
        design.
      </Text>
      <Title as="h3" size="h5" mb="s">
        Vision
      </Title>
      <Text>
        Every Digital Product Owner should be surrounded by a team of motivated professionals. A
        such team that functions as one organism, interconnected by shared goals, vision, and design
        processes. This way, it would allow you to stay on top of your competitors and always be one
        step ahead of them.
      </Text>
    </Box>
  </Inner>
);
